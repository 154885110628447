import React from "react";
//@ts-ignore
import Hyphenated from "react-hyphen";

const HyphenatedP = ({ children }: React.PropsWithChildren) => {
  return (
    <Hyphenated>
      <p>{children}</p>
    </Hyphenated>
  );
};

const Article = () => {
  return (
    <>
      <HyphenatedP>
        Greetings! I research the <i>human factors</i> of programming language
        design and want the next generation of programming languages to be more
        accessible.
      </HyphenatedP>

      <HyphenatedP>
        I’m a member of the{" "}
        <a href="https://cel.cs.brown.edu/">Cognitive Engineering Lab</a> at
        Brown University. My research focuses currently on <b>Rust</b>, but I
        secretly enjoy programming with parentheses.
      </HyphenatedP>

      <HyphenatedP>
        If you’re interested in my reseach don’t hesitate to{" "}
        <a href="mailto:gavinleroy@brown.edu">contact me.</a>
      </HyphenatedP>
    </>
  );
};

export default Article;
