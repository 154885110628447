import React, { type CSSProperties, useEffect, useState } from "react";

import { hexS, pythagorean, uniqueId } from "../funs";
import { generateHexSVG } from "./generateHexSVG";

const elevationStyleHover = (elevation: number) => {
  return {
    cursor: "pointer",
    transform: `translateY(${elevation / 2}px)`
  };
};

const elevationStyleActive = (elevation: number) => {
  return {
    cursor: "pointer",
    transition: "all 0.1s ease",
    transform: `translateY(${elevation}px)`
  };
};

interface HexagonProps {
  Content?: React.FC;
  sideLength?: number;
  borderRadius?: number;
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  elevation?: number;
  shadow?: string;
  img?: string;
  text?: string;
  textStyle?: CSSProperties;
  styles?: {
    normal: CSSProperties;
    hover: CSSProperties;
    active: CSSProperties;
  };
  href?: string;
  target?: string;
  onClick?: () => void;
}

export const DEFAULT_PROPS = {
  sideLength: 100,
  borderRadius: 12,
  fill: "white",
  stroke: "#bbb",
  strokeWidth: 0,
  elevation: 12,
  shadow: "#e2e2e2",
  textStyle: {},
  styles: {
    normal: {},
    hover: {},
    active: {}
  },
  onClick: () => {}
} as const;

const Hexagon = ({
  Content = () => null,
  sideLength = DEFAULT_PROPS.sideLength,
  borderRadius = DEFAULT_PROPS.borderRadius,
  fill = DEFAULT_PROPS.fill,
  stroke = DEFAULT_PROPS.stroke,
  strokeWidth = DEFAULT_PROPS.strokeWidth,
  elevation = DEFAULT_PROPS.elevation,
  shadow = DEFAULT_PROPS.shadow,
  img,
  text,
  textStyle = DEFAULT_PROPS.textStyle,
  styles = DEFAULT_PROPS.styles,
  href,
  target,
  onClick = DEFAULT_PROPS.onClick
}: HexagonProps) => {
  let mkStrokeWidth = (sw: number) => `${strokeWidth}px`;

  let thHexagonStyleBase = {
    userSelect: "none",
    stroke,
    strokeWidth: mkStrokeWidth(strokeWidth),
    transition: "all 0.2s ease"
  };

  const thHexagonStyleNormal = Object.assign(
    {},
    thHexagonStyleBase,
    styles.normal
  );

  const thHexagonStyleHover = Object.assign(
    {},
    thHexagonStyleBase,
    elevation ? elevationStyleHover(elevation) : {},
    styles.hover
  );

  const [thHexagonStyle, setThHexagonStyle] =
    useState<CSSProperties>(thHexagonStyleNormal);

  let width = Math.sqrt(3) * sideLength;
  let height = 2 * sideLength + elevation;

  let fontSize =
    typeof textStyle.fontSize === "string"
      ? Number.parseInt(textStyle.fontSize)
      : textStyle.fontSize;

  let fontSizeOffset = fontSize ? 0.3 * fontSize : 0;
  let yOffset = pythagorean(sideLength, hexS(sideLength) / 2) * 0.3;

  const clipId = "clipid-" + uniqueId();

  let InnerContent =
    text === undefined
      ? () => (
          <>
            <defs>
              <clipPath id={clipId}>
                <Path />
              </clipPath>
            </defs>
            <foreignObject
              xmlns="http://www.w3.org/2000/xhtml"
              width={width}
              height={height}
            >
              <div
                style={{
                  // To keep the inner elements from overflowing
                  width,
                  position: "fixed",
                  overflow: "hidden",
                  // Clip the inner element to the hexagon shape
                  // clipping the foreignObject doesn't work on mobile.
                  clipPath: `url(#${clipId})`,
                  WebkitClipPath: `url(#${clipId})`
                }}
              >
                <div
                  style={{
                    // We can't do this on the outer div because it's getting clipped.
                    // Use margin top to vertically lift the underlying component, but not
                    // the clip path itself.
                    marginTop: -yOffset
                  }}
                >
                  <Content />
                </div>
              </div>
            </foreignObject>
          </>
        )
      : () => (
          <text fill="#bbb" strokeWidth="0" style={textStyle}>
            <tspan
              x={width / 2}
              y={height / 2 + fontSizeOffset}
              textAnchor="middle"
            >
              {text}
            </tspan>
          </text>
        );

  const [children, setChildren] = useState<React.ReactElement | undefined>(
    undefined
  );

  useEffect(() => {
    setChildren(<InnerContent />);
  }, [sideLength]);

  const Path = ({
    str = stroke,
    strW = mkStrokeWidth(strokeWidth)
  }: {
    str?: string;
    strW?: string;
  }) => (
    <path
      stroke={str}
      strokeWidth={strW}
      fill={fill}
      d={generateHexSVG(sideLength, borderRadius)}
    />
  );

  const hexagon = (
    <React.Fragment>
      <Path />
      <image
        href={img}
        width={0.7 * width}
        height={0.7 * height}
        x={0.15 * width}
        y={0.12 * height}
      />
      {children}
    </React.Fragment>
  );

  return (
    <svg viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
      <svg y={elevation}>
        <path fill={shadow} d={generateHexSVG(sideLength, borderRadius)} />
      </svg>
      <g
        style={thHexagonStyle}
        onMouseEnter={() => setThHexagonStyle(thHexagonStyleHover)}
        onMouseLeave={() => setThHexagonStyle(thHexagonStyleNormal)}
        onClick={onClick}
      >
        {!href ? (
          hexagon
        ) : (
          <a href={href} target={target || "_blank"}>
            {hexagon}
          </a>
        )}
      </g>
    </svg>
  );
};

export default Hexagon;
