import { merge } from "lodash";
import React, { useEffect, useState } from "react";

import Hexagon, { DEFAULT_PROPS as HX_DEFS } from "./hexagon";
import classNames from "classnames";

const useCarousel = (tileCount: number, interval: number, reportActive: (i: number) => void = _i => {}) => {
  const [activeTile, setActiveTile] = useState(0);
  const updateActive = () => {
    const next = (activeTile + 1) % tileCount;
    setActiveTile(next);
    reportActive(next);
  };

  useEffect(() => {
    const intervalId = setInterval(updateActive, interval);
    return () => clearInterval(intervalId);
  }, [tileCount, interval, activeTile]);

  return activeTile;
};

interface TileProps {
  Content?: React.FC;
  fill?: string;
  stroke?: string;
  shadow?: string;
  img?: string;
  text?: string;
  textStyle?: object;
  styles?: {
    normal: object;
    hover: object;
    active: object;
  };
  href?: string;
  target?: string;
  onClick?: () => void;
}

interface StyleProps {
  normal: object;
  hover: object;
  active: object;
}

interface TiledHexagonsProps {
  tiles?: TileProps[];
  tileSideLengths?: number;
  tileBorderRadii?: number;
  tileElevations?: number;
  tileStrokeWidths?: number;
  tileGap?: number;
  tileStyles?: StyleProps;
  tileTextStyles?: object;
  maxHorizontal?: number;
  carouselInterval?: number;
  reportActive?: (i: number) => void;
}

const TiledHexagons = ({
  tiles = [],
  tileSideLengths = HX_DEFS.sideLength,
  tileBorderRadii = HX_DEFS.borderRadius,
  tileElevations = HX_DEFS.elevation,
  tileStrokeWidths = HX_DEFS.strokeWidth,
  tileGap = 4,
  tileStyles = {
    normal: {},
    hover: {},
    active: {}
  },
  tileTextStyles = {},
  maxHorizontal = 5,
  carouselInterval = 5000,
  reportActive = _i => {}
}: TiledHexagonsProps) => {
  let tileCount = tiles.length;
  if (tileCount == 0) return null;

  const activeTile = useCarousel(tileCount, carouselInterval, reportActive);

  let singleTileWidth = Math.sqrt(3) * tileSideLengths;
  let singleTileHeight = 2 * tileSideLengths + tileElevations;

  let columnCount = getColumnCount(tileCount, maxHorizontal);

  let XConst = singleTileWidth + tileGap;
  let YConst = (3 * tileSideLengths) / 2 + tileElevations + tileGap;

  let fullWidth =
    XConst * (maxHorizontal == 1 ? 1.5 : Math.min(tileCount, maxHorizontal));
  let fullHeight = singleTileHeight + tileGap + (columnCount - 1) * YConst;

  let ranges = getRanges(columnCount, maxHorizontal);

  return (
    <svg className="hexagons--container" width={fullWidth} height={fullHeight}>
      {tiles.map(
        (
          {
            Content,
            fill,
            stroke,
            shadow,
            img,
            text,
            textStyle,
            styles,
            href,
            target,
            onClick
          },
          i
        ) => {
          let { XMultiplier, YMultiplier } = getMultipliers(i, ranges);

          //deep merge & clone
          let mergedStyles = merge(tileStyles, styles);

          return (
            <svg
              className={classNames({ "active": i == activeTile })}
              key={i}
              x={XMultiplier * XConst}
              y={YMultiplier * YConst}
              width={singleTileWidth}
              height={singleTileHeight}
            >
              <Hexagon
                Content={Content}
                sideLength={tileSideLengths}
                borderRadius={tileBorderRadii}
                elevation={tileElevations}
                img={img}
                text={text}
                textStyle={Object.assign({}, tileTextStyles, textStyle)}
                styles={mergedStyles}
                fill={fill}
                stroke={stroke}
                strokeWidth={tileStrokeWidths}
                shadow={shadow}
                href={href}
                target={target}
                onClick={onClick}
              />
            </svg>
          );
        }
      )}
    </svg>
  );
};

const getRanges = (columnCount: number, maxHorizontal: number) => {
  if (maxHorizontal == 1) {
    return Array(columnCount)
      .fill(0)
      .map((_, i) => {
        return [i, i];
      }) as [number, number][];
  }

  let ranges = [[0, maxHorizontal - 1]];
  for (let c = 1; c <= columnCount; c++) {
    let evenOddModifier = c % 2 == 0 ? 0 : -1;
    ranges[c] = [
      ranges[c - 1][1] + 1,
      ranges[c - 1][1] + maxHorizontal + evenOddModifier
    ];
  }
  return ranges as [number, number][];
};

const getColumnCount = (tileCount: number, maxHorizontal: number) => {
  if (maxHorizontal == 1) return tileCount;
  let columnCount = 0;
  let i = 0;
  while (i <= tileCount) {
    i += columnCount % 2 == 0 ? maxHorizontal : maxHorizontal - 1;
    columnCount++;
  }
  return columnCount;
};

const getMultipliers = (i: number, ranges: [number, number][]) => {
  var YMultiplier = ranges.findIndex(range => i >= range[0] && i <= range[1]);
  var XMultiplier =
    i - ranges[YMultiplier][0] + (YMultiplier % 2 == 0 ? 0 : 0.5);
  return { XMultiplier, YMultiplier };
};

export default TiledHexagons;
