import classNames from "classnames";
import { type PropsWithChildren, useEffect, useState } from "react";

import "./Flier.scss";

export const RawFlier = ({ children }: PropsWithChildren) => {
  return <div className="flier">{children}</div>;
};

export const FlyingChar = ({ c }: { c: string }) => {
  return (
    <RawFlier>
      <span>{c}</span>
    </RawFlier>
  );
};

function pickFrom(chars: string): string {
  const i = Math.floor(Math.random() * chars.length);
  return chars[i];
}

function randomFlier(): [string, string, string] {
  const TB1 = () => pickFrom("ABCDEFGHIJMOPRSTVWcdhlmpqrstuvwy14");
  const TB2 = () => pickFrom("CDEFGNORYZcegiquvwy1");
  const CLR = () => pickFrom("ABC");
  return Math.random() < 0.5 ? [TB1(), "tb1", CLR()] : [TB2(), "tb2", CLR()];
}

export const RandomFlier = () => {
  const [_c, _ff, _clr] = randomFlier();
  const [char, setChar] = useState<string>(_c);
  const [ff, setFF] = useState<string>(_ff);
  const [color, setColor] = useState<string>(_clr);
  const timeout = 10_000;

  useEffect(() => {
    function run() {
      const [c, ff, clr] = randomFlier();
      setChar(c);
      setFF(ff);
      setColor(clr);
      setTimeout(run, timeout);
    }
    setTimeout(run, timeout);
  }, []);

  const cn = classNames("thingy", ff, color);
  return (
    <RawFlier>
      <span className={cn}>{char}</span>
    </RawFlier>
  );
};
