import Article from "./Article";
import { RandomFlier } from "./Flier";
import Showcase from "./Showcase";
import titleSVG from "./assets/name.svg";
import { local } from "./funs";

const NavBar = () => {
  const NI = ({ s, l }: { s: string; l: string }) => (
    <a className="nav-item" href={l}>
      {s}
    </a>
  );
  const navLabels = [
    ["GitHub", "https://github.com/gavinleroy"],
    ["CV", local("cv")],
    ["Blog", local("writings")]
  ];
  return (
    <div id="nav">
      {navLabels.map(([label, link], i) => (
        <NI key={i} s={label} l={link} />
      ))}
    </div>
  );
};

const BigName = () => {
  return (
    <div id="title">
      <img src={titleSVG} alt="My Name" />
    </div>
  );
};

const Info = () => {
  return (
    <div id="text">
      <Article />
    </div>
  );
};

const App = () => {
  return (
    <>
      <RandomFlier />
      <div id="home">
        <NavBar />
        <BigName />
        <Info />
        <Showcase />
      </div>
    </>
  );
};

export default App;
